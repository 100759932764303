import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { iconEmail, iconPhone, iconWhatsapp } from "../util/ui_util";
import { FooterContactUsButton } from "./FooterContactUsButton";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/app/AppState";
import iconGooglePlay from "../assets/images/icon_google_play.svg";
import iconAppStore from "../assets/images/icon_app_store.svg";
import { FooterNewsletter } from "./FooterNewsletter";

export const Footer = ({ isRtl }) => {
  const { t } = useTranslation();
  const { config } = useContext(AppContext);

  const LinkComponent = ({ text, url }) => (
    <NavLink to={url}>
      <div className="text-text hover:text-gray-400">{text}</div>
    </NavLink>
  );

  const PaymentMethod = ({ src }) => (
    <div className="flex flex-row items-center">
      <img src={src} alt="" className="w-[40px] object-scale-down" />
    </div>
  );

  const SocialMedia = ({ social }) => (
    <img
      src={social.image}
      alt={social.link}
      className="h-[40px] cursor-pointer select-none object-fill"
      onClick={() => {
        window.open(social.url, "_blank");
      }}
    />
  );

  const FooterLogo = () => (
    <div className="flex flex-col lg:w-[20vw] w-full space-y-2 items-center">
      {config?.data && (
        <img
          src={`${config.data.base_urls.ecommerce_image_url}/${config.data.app_logo}`}
          alt=""
          className="w-[300px] object-contain"
        />
      )}
      <div className="text-text text-center text-xs w-[300px]">
        {config?.data && config.data.footer_logo_text}
      </div>
    </div>
  );

  const FooterLinks = () => (
    <div className="flex flex-row justify-between lg:justify-normal space-x-2 lg:space-x-[5vw] rtl:space-x-reverse lg:w-[40vw] px-4 lg:px-0">
      <div className="flex flex-col space-y-2 py-4">
        <div className="text-text font-bold text-xl">{t("importantLinks")}</div>
        <div className="flex flex-col space-y-1">
          <LinkComponent text={t("privacyPolicy")} url="/privacy-policy" />
          <LinkComponent text={t("termsConditions")} url="/terms-conditions" />
          <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 rtl:space-x-reverse">
            {config.data?.play_store_config?.status && (
              <div
                className="flex flex-row md:w-1/2 items-center p-1 space-x-1 rtl:space-x-reverse bg-white rounded-md shadow-md cursor-pointer select-none"
                onClick={() => {
                  if (config.data?.play_store_config?.link) {
                    window.open(config.data?.play_store_config?.link, "_blank");
                  }
                }}
              >
                <div className="text-text text-xs w-full">
                  {t("downloadFromGooglePlay")}
                </div>
                <img
                  src={iconGooglePlay}
                  alt=""
                  className="w-[30px] object-scale-down"
                />
              </div>
            )}
            {config.data?.app_store_config?.status && (
              <div
                className="flex flex-row md:w-1/2 items-center p-1 space-x-1 rtl:space-x-reverse bg-white rounded-md shadow-md cursor-pointer select-none"
                onClick={() => {
                  if (config.data?.app_store_config?.link) {
                    window.open(config.data?.app_store_config?.link, "_blank");
                  }
                }}
              >
                <div className="text-text text-xs w-full">
                  {t("downloadFromAppStore")}
                </div>
                <img
                  src={iconAppStore}
                  alt=""
                  className="w-[30px] object-scale-down"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-1/2 md:w-[240px] space-y-2 py-4">
        <div className="text-text font-bold text-xl">{t("contactUs")}</div>
        {config?.data && (
          <div className="flex flex-wrap gap-y-2 gap-x-2">
            <div className="lg:flex-basis-[calc(50%-1.5rem)]">
              <FooterContactUsButton
                text={t("whatsapp")}
                genIcon={(color) =>
                  iconWhatsapp({ fillColor: color, width: "24px" })
                }
                onClick={() => {
                  const message = "Hello!";
                  const whatsappLink = `https://api.whatsapp.com/send?phone=${
                    config.data.whatsapp.number
                  }&text=${encodeURIComponent(message)}`;
                  window.open(whatsappLink, "_blank");
                }}
              />
            </div>
            <div className="lg:flex-basis-[calc(50%-1.5rem)]">
              <FooterContactUsButton
                text={t("phone")}
                genIcon={(color) =>
                  iconPhone({ fillColor: color, width: "24px" })
                }
                onClick={() => {
                  const telLink = `tel:${config.data.ecommerce_phone}`;
                  window.open(telLink, "_blank");
                }}
              />
            </div>
            <div className="lg:flex-basis-[calc(50%-1.5rem)]">
              <FooterContactUsButton
                text={t("email")}
                genIcon={(color) =>
                  iconEmail({ fillColor: color, width: "24px" })
                }
                onClick={() => {
                  const email = config.data.ecommerce_email;
                  const subject = "I need assistant";
                  const body = "Hello!";
                  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
                    subject
                  )}&body=${encodeURIComponent(body)}`;
                  window.open(mailtoLink, "_blank");
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col w-full bg-[#FCFCFC] divide-y divide-dashed divide-text"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:space-x-[5vw] rtl:space-x-reverse py-4">
          <FooterLogo />
          <FooterLinks />
          <FooterNewsletter />
        </div>
      </div>
      <div className="container mx-auto flex flex-col space-y-2 py-2">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-1 rtl:space-x-reverse">
            {config.data?.active_payment_method_list &&
              config.data?.active_payment_method_list.length > 0 &&
              config.data.active_payment_method_list.map(
                (paymentMethod, index) => (
                  <PaymentMethod
                    src={`${config.data.base_urls.gateway_image_url}/${paymentMethod.gateway_image}`}
                    key={index}
                  />
                )
              )}
          </div>
          {config?.data && (
            <div className="text-text text-sm">{config.data.footer_text}</div>
          )}
        </div>
        {config.data?.social_media && config.data?.social_media.length > 0 && (
          <div className="flex flex-row items-center space-x-1 rtl:space-x-reverse">
            <div className="text-text text-sm">{t("followUsOn")}</div>
            {config.data.social_media.map((social, index) => (
              <SocialMedia social={social} key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
