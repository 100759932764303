import React, { useState, useEffect, useContext } from "react";
import { HomeContext } from "../../context/home/HomeState";
import colors from "../../util/colors";
import { MoonLoader } from "react-spinners";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { Link } from "react-router-dom";

export const HomeSlider = () => {
  const { banners, getBanners } = useContext(HomeContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (banners.data.length === 0) {
      return;
    }
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.data.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [banners.data.length]);

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (banners.loading) {
    return (
      <div className="w-full flex flex-row justify-center">
        <MoonLoader color={colors.secondary} loading={true} size={24} />
      </div>
    );
  }

  if (banners.error) {
    return (
      <div className="w-full flex flex-row justify-center">{error.message}</div>
    );
  }

  return (
    <div className="relative w-full pb-[40.83%]">
      {/* Aspect Ratio 1200x490 */}
      {banners.data.map((banner, index) => (
        <Link
          to={
            banner.category_id
              ? `/category/${banner.category_id}`
              : banner.product_id
              ? `/product/${banner.product_id}`
              : ""
          }
          key={index}
        >
          <div
            className={`absolute transition-transform transform w-full h-full ${
              index === currentIndex
                ? "animate-slide-in"
                : index ===
                  (currentIndex - 1 + banners.data.length) % banners.data.length
                ? "animate-slide-out"
                : "opacity-0 pointer-events-none"
            }`}
          >
            <img
              src={banner.image_fullpath}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover rounded-2xl"
            />
          </div>
        </Link>
      ))}
    </div>
  );
};
