import React, { createContext, useReducer, useEffect } from "react";
import AppReducer from "./AppReducer";
import {
  getCategoriesApi,
  getConfigAPi,
  getNewsBannerApi,
  getUserApi,
  loginApi,
  registerApi,
  removeAccountApi,
  searchApi,
  subscribeToNewsletterApi,
  updateProfileApi,
} from "../../api/api";
import { useSnackbar } from "../general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getPrimaryColor,
  getPrimaryColorDark,
  setPrimaryColor,
} from "../../util/colors";

// Initial state
const initialState = {
  language: "ar",
  stopScroll: false,
  showLoginDialog: false,
  showSearchDialog: false,
  showProductQuickViewDialog: {
    show: false,
    product: null,
  },
  isAuthenticated: false,
  showAccountNavigation: false,
  primary: getPrimaryColor(),
  primaryDark: getPrimaryColorDark(),
  config: {
    data: null,
    loading: false,
    error: null,
  },
  user: {
    data: null,
    loading: false,
    error: null,
  },
  newsBanner: {
    data: null,
    loading: true,
    error: null,
  },
  categories: {
    data: [],
    loading: true,
    error: null,
  },
  token: {
    data: null,
    loading: false,
    error: null,
  },
  updatedProfile: {
    data: null,
    loading: false,
    error: null,
  },
  removedAccount: {
    data: null,
    loading: false,
    error: null,
  },
  searchProducts: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    hasMore: true,
  },
  newsletter: {
    data: null,
    loading: false,
    error: null,
  },
};

const getInitialState = () => {
  const savedState = localStorage.getItem("authState");
  const parsedState = savedState ? JSON.parse(savedState) : {};
  return { ...initialState, ...parsedState };
};

// Create context
export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, getInitialState());
  const { showSuccess, showError } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Save state to localStorage
  useEffect(() => {
    const {
      stopScroll,
      showLoginDialog,
      showSearchDialog,
      showProductQuickViewDialog,
      showAccountNavigation,
      user,
      newsBanner,
      categories,
      updatedProfile,
      removedAccount,
      searchProducts,
      newsletter,
      ...stateToPersist
    } = state;
    localStorage.setItem("authState", JSON.stringify(stateToPersist));
  }, [state]);

  // Actions
  function setLanguage(value) {
    dispatch({
      type: "SET_LANGUAGE",
      payload: value,
    });
  }

  function setStopScroll(value) {
    dispatch({
      type: "SET_STOP_SCROLL",
      payload: value,
    });
  }

  function setShowLoginDialog(value) {
    dispatch({
      type: "SET_SHOW_LOGIN_DIALOG",
      payload: value,
    });
  }

  function setShowSearchDialog(value) {
    dispatch({
      type: "SET_SHOW_SEARCH_DIALOG",
      payload: value,
    });
  }

  function setShowProductQuickViewDialog(value) {
    dispatch({
      type: "SET_SHOW_PRODUCT_QUICK_VIEW_DIALOG",
      payload: value,
    });
  }

  function setShowAccountNavigation(value) {
    dispatch({
      type: "SET_SHOW_ACCOUNT_NAVIGATION",
      payload: value,
    });
  }

  async function getConfig() {
    dispatch({
      type: "GET_CONFIG_REQUEST",
    });
    try {
      const res = await getConfigAPi();
      dispatch({
        type: "GET_CONFIG_SUCCESS",
        payload: res,
      });
      localStorage.setItem("latestConfig", JSON.stringify(res));
      setPrimaryColor({
        colorPrimary: res.primary_color,
        colorPrimaryDark: res.primary_color_dark,
      });
      // setPrimaryColor({
      //   colorPrimary: "#BB8713",
      //   colorPrimaryDark: "#9a7010",
      // });
    } catch (error) {
      dispatch({
        type: "GET_CONFIG_FAILURE",
        payload: error.message || "Failed to fetch config",
      });
    }
  }

  async function getUser() {
    const tokenString = state.token.data.token;
    dispatch({
      type: "GET_USER_REQUEST",
    });
    try {
      const user = await getUserApi({ token: tokenString });
      dispatch({
        type: "GET_USER_SUCCESS",
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_FAILURE",
        payload: error.message || "Failed to fetch user",
      });
    }
  }

  async function register(data) {
    dispatch({
      type: "REGISTER_REQUEST",
    });
    try {
      const res = await registerApi(data);
      dispatch({
        type: "REGISTER_SUCCESS",
        payload: res,
      });
      showSuccess(t("registrationSuccessful"));
    } catch (error) {
      dispatch({
        type: "REGISTER_FAILURE",
        payload: error.message || "Failed to register",
      });
    }
  }

  async function login(data) {
    dispatch({
      type: "REGISTER_REQUEST",
    });
    try {
      const res = await loginApi(data);
      dispatch({
        type: "REGISTER_SUCCESS",
        payload: res,
      });
      showSuccess(t("loginSuccessful"));
    } catch (error) {
      dispatch({
        type: "REGISTER_FAILURE",
        payload: error.message || "Failed to login",
      });
    }
  }

  function logout() {
    dispatch({
      type: "LOGOUT",
    });
    showSuccess(t("logoutSuccessful"));
  }

  async function getCategories() {
    dispatch({
      type: "GET_CATEGORIES_REQUEST",
    });
    try {
      const res = await getCategoriesApi();
      dispatch({
        type: "GET_CATEGORIES_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_CATEGORIES_FAILURE",
        payload: error.message || "Failed to fetch categories",
      });
    }
  }

  async function getNewsBanner() {
    dispatch({
      type: "GET_NEWS_BANNER_REQUEST",
    });
    try {
      const res = await getNewsBannerApi();
      dispatch({
        type: "GET_NEWS_BANNER_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_NEWS_BANNER_FAILURE",
        payload: error.message || "Failed to fetch news banner",
      });
    }
  }

  async function updateProfile(data) {
    const tokenString = state.token.data.token;
    dispatch({
      type: "UPDATE_PROFILE_REQUEST",
    });
    try {
      const res = await updateProfileApi({ token: tokenString, ...data });
      dispatch({
        type: "UPDATE_PROFILE_SUCCESS",
        payload: res,
      });
      showSuccess(t("profileUpdateSuccess"));
      getUser();
    } catch (error) {
      dispatch({
        type: "UPDATE_PROFILE_FAILURE",
        payload: error.message || "Failed to update profile",
      });
    }
  }

  async function removeAccount() {
    const tokenString = state.token.data.token;
    dispatch({
      type: "REMOVE_ACCOUNT_REQUEST",
    });
    try {
      const res = await removeAccountApi({ token: tokenString });
      dispatch({
        type: "REMOVE_ACCOUNT_SUCCESS",
        payload: res,
      });
      showSuccess(t("accountRemovedSuccess"));
      navigate("/");
    } catch (error) {
      dispatch({
        type: "REMOVE_ACCOUNT_FAILURE",
        payload: error.message || "Failed to remove account",
      });
    }
  }

  async function search({ query, page = 1 }) {
    dispatch({
      type: "SEARCH_REQUEST",
    });
    try {
      const res = await searchApi({ name: query, offset: page, limit: 10 });
      dispatch({
        type: "SEARCH_SUCCESS",
        payload: {
          searchProducts: res.products,
          hasMore:
            state.searchProducts?.data.length + res.products.length <
            res.total_size,
          page: page,
        },
        append: page > 1,
      });
    } catch (error) {
      dispatch({
        type: "SEARCH_FAILURE",
        payload: error.message || "Failed to search",
      });
    }
  }

  async function subscribeToNewsletter({ email }) {
    dispatch({
      type: "SUBSCRIBE_TO_NEWSLETTER_REQUEST",
    });
    try {
      const res = await subscribeToNewsletterApi({ email });
      dispatch({
        type: "SUBSCRIBE_TO_NEWSLETTER_SUCCESS",
        payload: res,
      });
      showSuccess(t("subscribeToNewsletterSuccess"));
    } catch (error) {
      dispatch({
        type: "SUBSCRIBE_TO_NEWSLETTER_FAILURE",
        payload: error.message || "Failed to subscribe to newsletter",
      });
      showError(error.message || "Failed to subscribe to newsletter");
    }
  }

  return (
    <AppContext.Provider
      value={{
        config: state.config,
        user: state.user,
        newsBanner: state.newsBanner,
        categories: state.categories,
        language: state.language,
        stopScroll: state.stopScroll,
        showLoginDialog: state.showLoginDialog,
        showSearchDialog: state.showSearchDialog,
        showProductQuickViewDialog: state.showProductQuickViewDialog,
        isAuthenticated: state.isAuthenticated,
        showAccountNavigation: state.showAccountNavigation,
        token: state.token,
        updatedProfile: state.updatedProfile,
        removedAccount: state.removedAccount,
        searchProducts: state.searchProducts,
        primary: state.primary,
        primaryDark: state.primaryDark,
        newsletter: state.newsletter,
        setLanguage,
        setStopScroll,
        setShowLoginDialog,
        setShowSearchDialog,
        setShowProductQuickViewDialog,
        setShowAccountNavigation,
        getConfig,
        getUser,
        register,
        login,
        logout,
        getNewsBanner,
        getCategories,
        updateProfile,
        removeAccount,
        search,
        subscribeToNewsletter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
