import axios from "axios";

const apiClient = axios.create({
  baseURL: window.location.origin,
  // baseURL: "https://tootalmasif.softlix.net",
  // baseURL: "https://alrayanherbal.com",
});

export const getConfigAPi = async () => {
  try {
    const response = await apiClient.get("/dash/api/v1/config");
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getNewsBannerApi = async () => {
  try {
    const response = await apiClient.get(`/dash/api/v1/banners/top-bar`);
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getCategoriesApi = async () => {
  try {
    const response = await apiClient.get(`/dash/api/v1/categories/all`);
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getBannersApi = async () => {
  try {
    const response = await apiClient.get("/dash/api/v1/banners");
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getAdsApi = async () => {
  try {
    const response = await apiClient.get("/dash/api/v1/banners/ads-banners");
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getFeaturedCategoriesApi = async () => {
  try {
    const response = await apiClient.get("/dash/api/v1/categories/featured");
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getNewArrivalsApi = async () => {
  try {
    const response = await apiClient.get("/dash/api/v1/products/new-arrival");
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getProductApi = async ({ productId }) => {
  try {
    const response = await apiClient.get(
      `/dash/api/v1/products/details/${productId}`
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getProductReviewsApi = async ({ productId }) => {
  try {
    const response = await apiClient.get(
      `/dash/api/v1/products/reviews/${productId}`
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const addReviewApi = async ({ token, productId, rating, comment }) => {
  try {
    const response = await apiClient.post(
      `/dash/api/v1/products/reviews/websubmit`,
      {
        product_id: productId,
        rating,
        comment,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getCategoryProductsApi = async ({ categoryId, page }) => {
  try {
    const response = await apiClient.get(
      `/dash/api/v1/categories/products-paginated/${categoryId}`,
      {
        params: { page },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const registerApi = async ({
  firstName,
  lastName,
  email,
  password,
  phone,
}) => {
  try {
    const response = await apiClient.post(`/dash/api/v1/auth/registration`, {
      f_name: firstName,
      l_name: lastName,
      email: email,
      password: password,
      phone: phone,
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const loginApi = async ({ phone, password }) => {
  try {
    const response = await apiClient.post(`/dash/api/v1/auth/login`, {
      email_or_phone: phone,
      password: password,
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getUserApi = async ({ token }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/customer/info", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const addAddressApi = async ({
  token,
  address,
  addressType,
  contactPersonName,
  contactPersonPhone,
  street,
  house,
  lat,
  lng,
  zipCode,
}) => {
  try {
    const response = await apiClient.post(
      "/dash/api/v1/customer/address/add",
      {
        address,
        address_type: addressType,
        contact_person_name: contactPersonName,
        contact_person_number: contactPersonPhone,
        road: street,
        house,
        latitude: lat,
        longitude: lng,
        postal_code: zipCode,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getAddressesApi = async ({ token }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/customer/address/list", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const deleteAddressesApi = async ({ token, addressId }) => {
  try {
    const response = await apiClient.delete(
      "/dash/api/v1/customer/address/delete",
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { address_id: addressId },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const checkCouponCodeApi = async ({ token, code }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/coupon/apply", {
      headers: { Authorization: `Bearer ${token}` },
      params: { code },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const placeOrderApi = async ({
  token,
  cart,
  couponDiscountAmount,
  couponTitle,
  couponCode,
  orderAmount,
  isPickup,
  addressId,
  paymentMethod,
  branchId,
}) => {
  try {
    const response = await apiClient.post(
      "/dash/api/v1/customer/order/place",
      {
        cart,
        coupon_discount_amount: couponDiscountAmount,
        coupon_discount_title: couponTitle,
        coupon_code: couponCode,
        order_amount: orderAmount,
        order_type: isPickup ? "self_pickup" : "delivery",
        delivery_address_id: addressId,
        payment_method: paymentMethod,
        branch_id: branchId,
        order_note: "",
        distance: -1,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const onlinePaymentApi = async ({
  token,
  customerId,
  orderAmount,
  paymentMethod,
  orderId,
}) => {
  try {
    const response = await apiClient.get("/dash/api/v1/online-payment", {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        customer_id: customerId,
        order_amount: orderAmount,
        payment_method: paymentMethod,
        order_id: orderId,
        payment_platform: "web",
      },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getOrdersApi = async ({ token }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/customer/order/list", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getNotificationsApi = async ({ token }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/notifications", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const addToWishlistApi = async ({ token, productIds }) => {
  try {
    const response = await apiClient.post(
      `/dash/api/v1/customer/wish-list/add`,
      {
        product_ids: [...productIds],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getWishlistApi = async ({ token, page }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/customer/wish-list", {
      params: { page },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const removeFromWishlistApi = async ({ token, productIds }) => {
  try {
    const response = await apiClient.delete(
      "/dash/api/v1/customer/wish-list/remove",
      {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          product_ids: [...productIds],
          _method: "delete",
        },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const updateProfileApi = async ({
  token,
  firstName,
  lastName,
  email,
  password,
  phone,
}) => {
  try {
    const response = await apiClient.put(
      `/dash/api/v1/customer/update-profile`,
      {
        f_name: firstName,
        l_name: lastName,
        email: email,
        password: password,
        phone: phone,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const removeAccountApi = async ({ token }) => {
  try {
    const response = await apiClient.delete(
      "/dash/api/v1/customer/remove-account",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const searchApi = async ({ name, limit, offset }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/products/search", {
      params: { name, limit, offset },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const trackOrderApi = async ({ token, orderId }) => {
  try {
    const response = await apiClient.get("/dash/api/v1/customer/order/track", {
      headers: { Authorization: `Bearer ${token}` },
      params: { order_id: orderId },
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getOrderDetailsApi = async ({ token, orderId }) => {
  try {
    const response = await apiClient.get(
      "/dash/api/v1/customer/order/details",
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { order_id: orderId },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const subscribeToNewsletterApi = async ({ email }) => {
  try {
    const response = await apiClient.post(`/dash/api/v1/subscribe-newsletter`, {
      email,
    });
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const getChatApi = async ({ token, limit, offset }) => {
  try {
    const response = await apiClient.get(
      "/dash/api/v1/customer/message/get-admin-message",
      {
        params: { limit, offset },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

export const sendMessageApi = async ({ token, message }) => {
  try {
    const response = await apiClient.post(
      `/dash/api/v1/customer/message/send-admin-message`,
      {
        message,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (e) {
    throw handleError(e);
  }
};

const handleError = (error) => {
  if (error.response) {
    // Server responded with a status other than 2xx
    const errorDetails = error.response.data.errors
      ? error.response.data.errors
          .map((err) => `${err.code}: ${err.message}`)
          .join(", ")
      : "Something went wrong";
    return new Error(`Error: ${error.response.status} - ${errorDetails}`);
  } else if (error.request) {
    // Request was made but no response was received
    return new Error(
      "Error: No response received from the server. Please try again."
    );
  } else {
    // Something else happened in setting up the request
    return new Error(`Error: ${error.message}`);
  }
};
