import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home/HomePage";
import { ProductPage } from "./pages/product/ProductPage";
import { CategoryPage } from "./pages/category/CategoryPage";
import useScrollToTop from "./hooks/useScrollToTop";
import { CartPage } from "./pages/cart/CartPage";
import { CheckoutPage } from "./pages/checkout/CheckoutPage";
import { OrdersPage } from "./pages/orders/OrdersPage";
import { WishlistPage } from "./pages/wishlist/WishlistPage";
import { ProfilePage } from "./pages/profile/ProfilePage";
import { NotificationsPage } from "./pages/notifications/NotificationsPage";
import { OrderPage } from "./pages/order/OrderPage";
import { PrivacyPolicyPage } from "./pages/privacy_policy/PrivacyPolicyPage";
import { TermsConditionsPage } from "./pages/terms_conditions/TermsConditionsPage";
import { ThankYouPage } from "./pages/thank_you/ThankYouPage";
import { PaymentFailure } from "./pages/payment_failure/PaymentFailure";
import CombinedProviders from "./context/CombinedProviders";
import { ChatPage } from "./pages/chat/ChatPage";

function App() {
  useScrollToTop();
  return (
    <CombinedProviders>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/category/:categoryId" element={<CategoryPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/orders/:orderId" element={<OrderPage />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/wishlist" element={<WishlistPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsConditionsPage />} />
        <Route path="/thank-you/:orderId" element={<ThankYouPage />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/chat" element={<ChatPage />} />
      </Routes>
    </CombinedProviders>
  );
}

export default App;
